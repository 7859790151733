export const TextIconBoxType = {
  Welcome: 'Welcome',
  AdditionalInfo: 'AdditionalInfo',
  NearlyThere: 'NearlyThere',
  Congratulations: 'Congratulations',
  PersonalTipping: 'PersonalTipping',
  InviteGuides: 'InviteGuides',
  SetupReviews: 'SetupReviews',
  OrderCards: 'OrderCards',
  NoTipsYet: 'NoTipsYet',
  PreviewTippingFlow: 'PreviewTippingFlow',
  GetPaid: 'GetPaid',
  PersonalQR: 'PersonalQR',
  PrintOut: 'PrintOut',
  GuideGallery: 'GuideGallery',
  CompanyInviteLink: 'CompanyInviteLink',
  PaymentLinkQR: 'PaymentLinkQR',
  CommissionPercentage: 'CommissionPercentage',
  CommissionPaymentLink: 'CommissionPaymentLink',
  CommissionWarningEnable: 'CommissionWarningEnable',
  CommissionWarningDisable: 'CommissionWarningDisable'
};
