import { Analytics } from '@analytics';
import LeftArrow from '@assets/images/Left.svg';
import TextIconInformationBox from '@components/Onboarding/TextIconInformationBox';
import { TextIconBoxType } from '@components/Onboarding/constants';
import MobileConfirmationDialog from '@components/TapToTip/MobileConfirmationDialog';
import { Button, Switch, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles({
  commissionDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px 20px 20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '12px',
    position: 'relative',
    height: 'auto',
    width: '100%',
    marginBottom: '20px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  text: {
    fontSize: '16px',
    fontWeight: '600',
    marginBottom: '10px',
    color: '#000'
  },
  subText: {
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Montserrat',
    color: '#767777'
  },
  textContainer: {
    marginBottom: '15px'
  },
  commissionRateBox: {
    width: '100%',
    minHeight: '102px',
    paddingRight: '22px',
    paddingTop: '20px',
    paddingLeft: '21px',
    paddingBottom: '13px',
    borderRadius: '22px',
    flexDirection: 'column',
    backgroundColor: '#fff',
    margin: '20px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.21)'
  },
  commissionRateText: {
    fontSize: '17px',
    fontWeight: '600',
    color: '#000',
    marginBottom: '10px'
  },
  commissionActiveToggle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 'auto',
    color: '#767777'
  },
  textField: {
    fontSize: '14px',
    marginBottom: '20px',
    marginTop: '20px'
  },
  backButton: {
    width: '50px',
    minWidth: 'unset',
    padding: '12px'
  },
  backIcon: {
    width: '20px'
  },
  switch: {
    '& .MuiSwitch-thumb': {
      backgroundColor: '#fff'
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#e0e3e0'
    },
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#4dfa43'
    }
  },
  cancelButton: {
    color: '#767777'
  }
});

const CommissionSettings = ({
  paymentCommission,
  handleReturn,
  handleUpdateCommissionSettings
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const [commissionSettings, setCommissionSettings] = React.useState(paymentCommission);
  const [isConfirmationDialogShown, setIsConfirmationDialogShown] = React.useState(false);
  const [step, setActiveStep] = React.useState(0);

  const handlePercentageChange = text => {
    let number = +text.replace(/[^0-9]/g, '');
    if (number > 100) {
      number = 100;
    }
    setCommissionSettings({ ...commissionSettings, percentage: number });
  };

  const handleDecreaseStep = (submit = false) => {
    if (step === 0) {
      handleReturn();
    }
    setActiveStep(step - 1);
    if (!submit) {
      setCommissionSettings(paymentCommission);
    }
  };

  const handleSubmit = async (redirect = true) => {
    handleUpdateCommissionSettings(commissionSettings);
    Analytics.track('updated commission settings', {
      enabled: commissionSettings.enabled,
      percentage: commissionSettings.percentage
    });
    redirect && handleDecreaseStep(true);
  };

  const handleSwitch = () => {
    setIsConfirmationDialogShown(true);
    setCommissionSettings({ ...commissionSettings, enabled: !commissionSettings?.enabled });
  };

  const handleConfirmSwitch = () => {
    handleSubmit(false);
    setIsConfirmationDialogShown(false);
  };

  const handleCancelSwitch = () => {
    setCommissionSettings({ ...commissionSettings, enabled: !commissionSettings?.enabled });
    setIsConfirmationDialogShown(false);
  };

  const steps = [
    {
      key: 'settingsPage',
      subTitle: intl.formatMessage(messages.settingsPageSubTitle)
    },
    {
      key: 'percentage',
      subTitle: intl.formatMessage(messages.percentageSubTitle)
    }
  ];

  const buttons = [
    <Button
      key="confirm-button"
      className={classes.removeButton}
      color="primary"
      variant="contained"
      onClick={() => {
        handleConfirmSwitch();
      }}>
      {intl.formatMessage(messages.confirmAndSave)}
    </Button>,
    <Button
      key="cancel-button"
      className={classes.cancelButton}
      color="secondary"
      onClick={() => handleCancelSwitch()}>
      {intl.formatMessage(messages.cancel)}
    </Button>
  ];

  let renderBody = () => {
    switch (steps[step]?.key) {
      case 'settingsPage':
        return (
          <>
            <Button className={classes.backButton} onClick={() => handleDecreaseStep()}>
              <img src={LeftArrow} alt="" className={classes.backIcon} />
            </Button>
            <div className={classes.textContainer}>
              <div className={classes.text}>{intl.formatMessage(messages.commission)}</div>
              <div className={classes.subText}>{steps[step].subTitle}</div>
            </div>
            <div className={classes.commissionDetailsContainer}>
              <div className={classes.commissionActiveToggle}>
                <div>{intl.formatMessage(messages.active)}</div>
                <Switch
                  className={classes.switch}
                  onChange={handleSwitch}
                  checked={commissionSettings?.enabled}
                />
              </div>
              <TextIconInformationBox
                card={TextIconBoxType.CommissionPaymentLink}
                noBackgroundCircle={true}
              />
              <div className={classes.commissionRateBox}>
                <div className={classes.commissionRateText}>
                  {intl.formatMessage(messages.commissionRate, {
                    percentage: commissionSettings?.percentage
                  })}
                </div>
              </div>
              <Button key="edit-commission" buttonColor="primary" onClick={() => setActiveStep(1)}>
                {intl.formatMessage(messages.edit)}
              </Button>
            </div>
          </>
        );
      case 'percentage':
        return (
          <>
            <Button className={classes.backButton} onClick={() => handleDecreaseStep()}>
              <img src={LeftArrow} alt="" className={classes.backIcon} />
            </Button>
            <div className={classes.textContainer}>
              <div className={classes.text}>{intl.formatMessage(messages.commission)}</div>
              <div className={classes.subText}>{steps[step].subTitle}</div>
            </div>

            <div className={classes.commissionDetailsContainer}>
              <TextField
                label={intl.formatMessage(messages.percentageAmount)}
                placeholder="0"
                type="number"
                value={commissionSettings?.percentage.toString()}
                className={classes.textField}
                onChange={e => handlePercentageChange(e.target.value)}
                maxLength={10}
                variant="outlined"
                required
              />
              <Button
                key="edit-commission"
                color="primary"
                variant="contained"
                onClick={() => handleSubmit()}>
                {intl.formatMessage(messages.saveAndApply)}
              </Button>
            </div>
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <div className={classes.container}>{renderBody()}</div>
      <MobileConfirmationDialog
        isShown={isConfirmationDialogShown}
        buttons={buttons}
        close={handleCancelSwitch}
        textIconBoxType={
          commissionSettings?.enabled
            ? TextIconBoxType.CommissionWarningEnable
            : TextIconBoxType.CommissionWarningDisable
        }
      />
    </>
  );
};

export default CommissionSettings;
