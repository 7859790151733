import { Button, Card, CardContent, CardMedia, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import MobileLogoIcon from '@assets/images/TripAdmitMobile.svg';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  banner: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    width: '100%',
    marginBottom: 4,
    borderBottom: '1px solid #f4f4f4'
  },
  appIcon: {
    width: 40,
    height: 40,
    borderRadius: 8,
    marginRight: 8
  },
  cardContent: {
    flex: 1,
    padding: '8px 0'
  },
  button: {
    textTransform: 'none'
  },
  iconButton: {
    marginLeft: 8,
    padding: 8
  }
});

const HIDE_BANNER_KEY = 'hideBannerSession';
const EXPIRATION_DAYS = 14;

const MobileAppBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const classes = useStyles();

  // Check expiration and visibility logic
  useEffect(() => {
    const now = Date.now();
    const hideUntil = sessionStorage.getItem(HIDE_BANNER_KEY);

    if (!hideUntil || now > parseInt(hideUntil, 10)) {
      setIsVisible(true);
    }
  }, []);

  // Determine device type and screen size
  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsAndroid(/android/i.test(userAgent));
    setIsIOS(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);

    const checkMobile = () => setIsMobile(window.innerWidth < 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Open the app using deep link and fallback to Play Store
  const openApp = () => {
    const deepLink = 'https://eu.tip.direct';
    window.location.href = deepLink;

    setTimeout(() => {
      if (isAndroid) {
        window.open(
          'https://play.google.com/store/apps/details?id=com.tripadmit.TipDirect',
          '_blank'
        );
      }
    }, 2000);

    hideBannerForTwoWeeks();
  };

  const hideBannerForTwoWeeks = () => {
    const expirationTime = Date.now() + EXPIRATION_DAYS * 24 * 60 * 60 * 1000; // Calculate 2 weeks from now
    sessionStorage.setItem(HIDE_BANNER_KEY, expirationTime.toString()); // Store the expiration timestamp
    setIsVisible(false);
  };

  // If banner should be hidden (not mobile, dismissed, or on iOS), don't render
  if (!isVisible || !isMobile || isIOS) return null;

  return (
    <Card elevation={0} className={classes.banner}>
      <CardMedia
        component="img"
        image={MobileLogoIcon}
        alt="App Icon"
        className={classes.appIcon}
      />
      <CardContent className={classes.cardContent}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          TipDirect
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Open in the TipDirect app
        </Typography>
      </CardContent>
      <Button variant="contained" onClick={openApp} color="primary" className={classes.button}>
        OPEN
      </Button>
      <IconButton size="small" onClick={hideBannerForTwoWeeks} className={classes.iconButton}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Card>
  );
};

export default MobileAppBanner;
