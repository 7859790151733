import { defineMessages } from 'react-intl';

export const scope = 'app.components.TipDirect.AgentsProfilesTable';

export default defineMessages({
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status'
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name'
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email'
  },
  numberOfCards: {
    id: `${scope}.numberOfCards`,
    defaultMessage: 'Number of Cards'
  },
  users: {
    id: `${scope}.users`,
    defaultMessage: 'Users'
  },
  inviteGuides: {
    id: `${scope}.inviteGuides`,
    defaultMessage: 'Invite Guides'
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: 'Manage'
  },
  createTour: {
    id: `${scope}.createTour`,
    defaultMessage: 'Create Tour'
  },
  copiedToClipboard: {
    id: `${scope}.copiedToClipboard`,
    defaultMessage: 'Copied to clipboard'
  },
  guideQRs: {
    id: `${scope}.guideQRs`,
    defaultMessage: 'Guide QRs'
  },
  assignedTours: {
    id: `${scope}.assignedTours`,
    defaultMessage: 'Assigned Tours'
  }
});
