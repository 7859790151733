import { getStripeConnectSessionForUserPayments } from '@api/user';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { ConnectComponentsProvider, ConnectPayouts } from '@stripe/react-connect-js';
import React, { useState } from 'react';

const AccountPayoutsUI = ({ user }) => {
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const response = await getStripeConnectSessionForUserPayments();
      if (!response.status == 200) {
        // Handle errors on the client side here
        const { error } = await response.data;
        console.error('An error occurred: ', error);
        //document.querySelector('#error').removeAttribute('hidden');
        return undefined;
      } else {
        const { client_secret: clientSecret } = await response.data;
        //document.querySelector('#error').setAttribute('hidden', '');
        return clientSecret;
      }
    };

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey:
        user.profile.country.toUpperCase() === 'US'
          ? process.env.REACT_APP_STRIPE_US
          : process.env.REACT_APP_STRIPE_DEFAULT,
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          colorPrimary: '#6997FF',
          buttonPrimaryColorBackground: '#6997FF',
          buttonPrimaryColorBorder: '#6997FF',
          buttonPrimaryColorText: '#FFFFFF',
          actionPrimaryColorText: '#6997FF',
          actionPrimaryTextDecorationColor: '#6997FF',
          formHighlightColorBorder: '#6997FF',
          formAccentColor: '#6997FF',
          colorDanger: '#E926BF',
          badgeSuccessColorText: '#3CE5F4',
          badgeWarningColorText: '#D73EC6',
          badgeDangerColorText: '#D73EC6',
          spacingUnit: '12px',
          fontFamily: "'Montserrat',Helvetica,Arial,sans-serif"
        }
      }
    });
  });

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectPayouts />
    </ConnectComponentsProvider>
  );
};

export default AccountPayoutsUI;
