import { Analytics } from '@analytics';
import DownloadSvg from '@assets/images/tip_direct/Download.svg';
import QRSvg from '@assets/images/tip_direct/QR.svg';
import TopNav from '@components/Nav/TopNav';
import TipDirectLeftMenu from '@components/Nav/TopNav/TipDirectLeftMenu';
import localizationMessages from '@components/Resources/ResourcesTable/messages';
import AccessControlledButton from '@components/TapToTip/AccessControlledButton';
import AgentProfileSummaryButton from '@components/TapToTip/AgentProfileSummaryButton';
import InviteGuideModal from '@components/TapToTip/InviteGuideModal';
import InviteMenuButton from '@components/TapToTip/InviteMenuButton';
import { regionalURLs } from '@constants';
import { Button, CircularProgress, Paper, Snackbar } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { StyleBreakpoints } from '@utils/constants';
import { useIsFeatureForCompanyEnabled } from '@utils/hooks';
import { useInjectReducer } from '@utils/injectReducer';
import { useInjectSaga } from '@utils/injectSaga';
import JSZip from 'jszip';
import MaterialTable from 'material-table';
import QRCode from 'qrcode';
import { default as React, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as actions from './actions';
import { StatusBackgroundColor, StatusForeColor, StatusType, UserType } from './constants';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

const useStyles = makeStyles(theme => ({
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      flexDirection: 'row'
    }
  },
  striped: {
    '& .MuiTableRow-root': {
      '&:nth-child(even)': {
        backgroundColor: '#FAF7FA'
      }
    }
  },
  container: {
    padding: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'flex-start',
    width: '80%',
    flexWrap: 'wrap',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      width: '100%',
      padding: '24px 16px'
    }
  },
  table: {
    width: '100%'
  },
  status: {
    textAlign: 'center',
    width: '66px',
    padding: '4px 0',
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '4px'
  },
  topItems: {
    margin: '0 0 20px auto',
    display: 'flex',
    gap: '15px'
  },
  guideQRs: {
    padding: '6px 20px',
    borderRadius: '6px',
    boxShadow: '0 3px 3px 0 rgba(0, 0, 0, 0.13)',
    backgroundColor: '#fff',
    color: '#000'
  },
  guideQRsText: {
    marginRight: '4px',
    minWidth: '76px'
  }
}));

const AgentStatus = ({ status, classes }) => {
  return (
    <div
      className={classes.status}
      style={{
        backgroundColor: StatusBackgroundColor[status],
        color: StatusForeColor[status]
      }}>
      {StatusType[status]}
    </div>
  );
};

const AgentsProfilesTable = ({
  agentsProfiles,
  sendInvitesToGuides,
  revokeInvite,
  fetchAgentsProfiles,
  addToursToProfile,
  activeTours,
  fetchActiveTours,
  user,
  isLoading
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [showAlert, setShouldShowCopyAlert] = useState(false);
  const [isQRCodeLoading, setIsQRCodeLoading] = useState(false);

  useInjectReducer({ key: 'tipAgents', reducer });
  useInjectSaga({ key: 'tipAgents', saga });

  const handleQRDownload = async (url, firstName, lastName) => {
    const data = await QRCode.toDataURL(url).catch(err => {
      console.error(err);
    });

    let a = document.createElement('a');
    a.download = `Tip-Direct-${firstName || ''}-${lastName || ''}.png`;
    a.href = data;
    a.click();
  };

  const downloadQRCodesAsZip = async () => {
    await new Promise(resolve => {
      setTimeout(resolve, 1000); // Simulate a delay of 1000 milliseconds (1 second)
    });

    Analytics.track('download all QR codes clicked', {
      page: 'users table'
    });
    const zip = new JSZip();
    const promises = [];

    agentsProfiles.forEach((guide, index) => {
      if (guide.type !== 'user' && guide.type !== 'admin') return;

      const { userId, firstName, lastName } = guide;
      const url = (regionalURLs[process.env.REGION] || regionalURLs.US) + `t/g/${userId}`;

      const qrFileName = `${firstName || ''}-${lastName || ''}.png`;
      const promise = QRCode.toDataURL(url)
        .then(data => {
          zip.file(qrFileName, data.substring(data.indexOf(',') + 1), { base64: true });
        })
        .catch(err => {
          console.error(`Error generating QR code for guide at index ${index}:`, err);
        });
      promises.push(promise);
    });

    await Promise.all(promises);

    const zipData = await zip.generateAsync({ type: 'blob' });
    const zipFileName = 'QR_Codes.zip';
    const a = document.createElement('a');
    a.download = zipFileName;
    a.href = URL.createObjectURL(zipData);
    a.click();
  };

  const downloadAllQRCodes = async () => {
    try {
      setIsQRCodeLoading(true);
      await downloadQRCodesAsZip();
    } catch (err) {
      console.error('Error downloading QR codes:', err);
    } finally {
      setIsQRCodeLoading(false);
    }
  };

  useEffect(() => {
    if (!agentsProfiles?.length) {
      fetchAgentsProfiles();
    }

    if (!activeTours?.length) {
      fetchActiveTours();
    }
  }, []);

  const isFeatureForCompanyEnabled = useIsFeatureForCompanyEnabled();
  const isReviewCollectionEnabled = isFeatureForCompanyEnabled('PostTipReviewCollection');

  const columns = [
    {
      field: 'status',
      title: intl.formatMessage(messages.status),
      render: rowData => <AgentStatus status={rowData.status} classes={classes} />
    },
    {
      field: 'type',
      title: intl.formatMessage(messages.type),
      render: rowData => UserType[rowData.type]
    },
    {
      field: 'name',
      title: intl.formatMessage(messages.name),
      render: rowData => `${rowData.firstName || ''} ${rowData.lastName || ''}`,
      customSort: (a, b) => {
        return `${a.firstName || ''} ${a.lastName || ''}`.localeCompare(
          `${b.firstName || ''} ${b.lastName || ''}`
        );
      }
    },
    {
      field: 'email',
      title: intl.formatMessage(messages.email)
    },
    {
      field: 'numberOfCards',
      title: intl.formatMessage(messages.numberOfCards),
      render: rowData => rowData.assignedCardsAmount,
      customSort: (a, b) => a.assignedCardsAmount - b.assignedCardsAmount
    },
    {
      field: 'assignedTours',
      title: intl.formatMessage(messages.assignedTours),
      render: rowData => (rowData.type === 'invite' ? '' : rowData.tours.length),
      customSort: (a, b) => a.tours?.length - b.tours?.length
    },
    {
      title: intl.formatMessage(messages.manage),
      field: 'manage',
      sorting: false,
      headerStyle: {
        paddingRight: '25px',
        textAlign: 'right'
      },
      cellStyle: {
        textAlign: 'right'
      },
      render: rowData => {
        if (rowData.type === 'user' || rowData.type === 'admin') {
          return (
            <AgentProfileSummaryButton
              activeTours={activeTours}
              profile={rowData}
              addToursToProfile={addToursToProfile}
              userId={rowData.userId}
              handleQRDownload={handleQRDownload}
              setShouldShowCopyAlert={setShouldShowCopyAlert}
              isReviewCollectionEnabled={isReviewCollectionEnabled}
            />
          );
        }

        if (rowData.type === 'invite') {
          return (
            <InviteMenuButton
              revokeInvite={revokeInvite}
              inviteId={rowData.inviteId}
              sendInvite={sendInvitesToGuides}
              inviteeEmail={rowData.email}
            />
          );
        }
      }
    }
  ];

  return (
    <>
      <TopNav />
      <div className={classes.outerContainer}>
        <TipDirectLeftMenu selectedTab={'Users'} />
        <div className={classes.container}>
          <div className={classes.topItems}>
            <Button
              disabled={!agentsProfiles?.length || isQRCodeLoading}
              className={classes.guideQRs}
              startIcon={<img src={QRSvg} alt="QR" />}
              endIcon={<img src={DownloadSvg} alt="Download" />}
              onClick={downloadAllQRCodes}>
              <span className={classes.guideQRsText}>
                {isQRCodeLoading ? (
                  <CircularProgress size={18} />
                ) : (
                  intl.formatMessage(messages.guideQRs)
                )}
              </span>
            </Button>
            <AccessControlledButton
              shouldCheckFeature={false}
              title={intl.formatMessage(messages.inviteGuides)}
              onClick={() => {
                Analytics.track('invite guides open');
                setIsInviteModalOpen(true);
              }}
            />
          </div>

          <MaterialTable
            isLoading={isLoading}
            style={{ width: '100%' }}
            title={intl.formatMessage(messages.users)}
            columns={columns}
            data={agentsProfiles}
            components={{
              // eslint-disable-next-line react/display-name
              Container: props => <Paper className={classes.striped} {...props} />
            }}
            options={{
              pageSize: 10,
              tableLayout: 'auto',
              emptyRowsWhenPaging: false,
              headerStyle: {
                whiteSpace: 'nowrap',
                position: 'sticky',
                top: '0'
              },
              draggable: false,
              cellStyle: {
                whiteSpace: 'nowrap',
                width: 'fit-content'
              },
              maxBodyHeight: window.innerWidth > StyleBreakpoints.sm ? '75vh' : '55vh'
            }}
            localization={{
              pagination: {
                labelRowsSelect: intl.formatMessage(localizationMessages.labelRowsSelect),
                labelRowsPerPage: intl.formatMessage(localizationMessages.labelRowsPerPage),
                firstAriaLabel: intl.formatMessage(localizationMessages.firstAriaLabel),
                firstTooltip: intl.formatMessage(localizationMessages.firstTooltip),
                previousAriaLabel: intl.formatMessage(localizationMessages.previousAriaLabel),
                previousTooltip: intl.formatMessage(localizationMessages.previousTooltip),
                nextAriaLabel: intl.formatMessage(localizationMessages.nextAriaLabel),
                nextTooltip: intl.formatMessage(localizationMessages.nextTooltip),
                lastAriaLabel: intl.formatMessage(localizationMessages.lastAriaLabel),
                lastTooltip: intl.formatMessage(localizationMessages.lastTooltip)
              },
              toolbar: {
                searchTooltip: intl.formatMessage(localizationMessages.searchTooltip),
                searchPlaceholder: intl.formatMessage(localizationMessages.searchPlaceholder)
              },
              body: {
                emptyDataSourceMessage: intl.formatMessage(
                  localizationMessages.emptyDataSourceMessage
                )
              }
            }}
          />

          <InviteGuideModal
            isOpen={isInviteModalOpen}
            setIsOpen={setIsInviteModalOpen}
            sendInvitesToGuides={sendInvitesToGuides}
            companyName={user.companyName}
            inviteCode={user.inviteCode}
          />
        </div>
      </div>
      <Snackbar
        open={showAlert}
        autoHideDuration={10000}
        onClose={() => {
          setShouldShowCopyAlert(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <MuiAlert
          variant="filled"
          elevation={6}
          severity="success"
          onClose={() => {
            setShouldShowCopyAlert(false);
          }}>
          {intl.formatMessage(messages.copiedToClipboard)}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = state => ({
  agentsProfiles: state.tipAgents?.agentsProfiles,
  activeTours: state.tipAgents?.activeTours,
  isLoading: state.tipAgents?.isLoading,
  user: state.user?.user
});

const mapDispatchToProps = dispatch => ({
  fetchAgentsProfiles: () => dispatch(actions.fetchAgentsProfiles()),
  sendInvitesToGuides: payload => dispatch(actions.sendInvitesToGuides(payload)),
  addToursToProfile: (userId, tourIds) => dispatch(actions.addToursToProfile({ userId, tourIds })),
  revokeInvite: payload => dispatch(actions.revokeInvite(payload)),
  fetchActiveTours: () => dispatch(actions.fetchActiveTours())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentsProfilesTable);
