import { TextIconBoxType } from '@components/Onboarding/constants';
import TextIconInformationBox from '@components/Onboarding/TextIconInformationBox';
import { setupSubScreens, SubMenuTabs } from '@constants';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '12px',
    position: 'relative',
    height: 'auto',
    width: '100%',
    marginBottom: '20px'
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '12px'
  },
  actionButton: {
    width: '100%',
    marginTop: '16px'
  },
  labelStyle: {
    fontSize: '14px',
    fontWeight: '700',
    letterSpacing: '0.5',
    lineHeight: '14px'
  },
  contentStyle: {
    height: '34px'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '18px'
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: '14px'
  },
  title: {
    fontSize: '18px',
    color: '#000',
    fontWeight: 'bold'
  }
});

export default function CommissionsLink({ handleSubScreenSwitch }) {
  const classes = useStyles();
  const intl = useIntl();

  const handleShowCommissionSettings = () => {
    handleSubScreenSwitch(setupSubScreens.commission);
  };

  return (
    <div className={classes.container} id={SubMenuTabs.Setup.COMMISSION}>
      <div className={classes.header}>
        <div className={classes.infoBlock}>
          <div className={classes.title}>{intl.formatMessage(messages.commission)}</div>
        </div>
      </div>

      <div className={classes.content}>
        <TextIconInformationBox
          card={TextIconBoxType.CommissionPercentage}
          noBackgroundCircle={true}
          betaTag={true}
        />
      </div>

      <div>
        <Button
          className={classes.actionButton}
          color="primary"
          variant="contained"
          onClick={() => handleShowCommissionSettings()}>
          {intl.formatMessage(messages.select)}
        </Button>
      </div>
    </div>
  );
}
