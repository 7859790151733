import { defineMessages } from 'react-intl';

const scope = 'app.components.TapToTip.Setup.CommissionsLink';

export default defineMessages({
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Select'
  },
  commission: {
    id: `${scope}.commission`,
    defaultMessage: 'Commission'
  }
});
