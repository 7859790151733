import { defineMessages } from 'react-intl';

const scope = 'app.components.TapToTip.Setup.CommissionsSettings';

export default defineMessages({
  settingsPageSubTitle: {
    id: `${scope}.settingsPageSubTitle`,
    defaultMessage: 'Select the product you would like to add a commission to'
  },
  percentageSubTitle: {
    id: `${scope}.percentageSubTitle`,
    defaultMessage: 'Set how much commission guides can earn'
  },
  confirmAndSave: {
    id: `${scope}.confirmAndSave`,
    defaultMessage: 'Confirm & Save'
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel'
  },
  commission: {
    id: `${scope}.commission`,
    defaultMessage: 'Commission'
  },
  commissionRate: {
    id: `${scope}.commissionRate`,
    defaultMessage: 'Commission Rate: {percentage}%'
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit'
  },
  percentageAmount: {
    id: `${scope}.percentageAmount`,
    defaultMessage: 'Percentage Amount'
  },
  saveAndApply: {
    id: `${scope}.saveAndApply`,
    defaultMessage: 'Save & Apply to all'
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Active'
  }
});
