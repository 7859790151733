import { getStripeConnectSessionForUserPayments } from '@api/user';
import { CircularProgress } from '@material-ui/core';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { ConnectBalances, ConnectComponentsProvider } from '@stripe/react-connect-js';
import React, { useState } from 'react';

const DashboardBalance = ({ user }) => {
  const [hasConnection, setHasConnection] = useState(false);
  const [hasLoadedSCInstance, setHasLoadedSCInstance] = useState(false);
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await getStripeConnectSessionForUserPayments();

        if (!response.status == 200) {
          // Handle errors on the client side here
          const { error } = await response.data;
          console.error('An error occurred: ', error);
          //document.querySelector('#error').removeAttribute('hidden');
          return undefined;
        } else {
          const { client_secret: clientSecret } = await response.data;
          setHasConnection(true);
          //document.querySelector('#error').setAttribute('hidden', '');
          return clientSecret;
        }
      } catch (error) {
        // Handle errors on the client side here
        console.error('An error occurred: ', error);
        setHasConnection(false);
        //document.querySelector('#error').removeAttribute('hidden');
        return undefined;
      } finally {
        setHasLoadedSCInstance(true);
      }
    };

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey:
        user.profile.country.toUpperCase() === 'US'
          ? process.env.REACT_APP_STRIPE_US
          : process.env.REACT_APP_STRIPE_DEFAULT,
      fetchClientSecret: fetchClientSecret,
      appearance: {
        variables: {
          colorPrimary: '#6997FF',
          buttonPrimaryColorBackground: '#6997FF',
          buttonPrimaryColorBorder: '#6997FF',
          buttonPrimaryColorText: '#FFFFFF',
          actionPrimaryColorText: '#6997FF',
          actionPrimaryTextDecorationColor: '#6997FF',
          formHighlightColorBorder: '#6997FF',
          formAccentColor: '#6997FF',
          colorDanger: '#E926BF',
          badgeSuccessColorText: '#3CE5F4',
          badgeWarningColorText: '#D73EC6',
          badgeDangerColorText: '#D73EC6',
          spacingUnit: '12px',
          fontFamily: "'Montserrat',Helvetica,Arial,sans-serif"
        }
      }
    });
  });

  const onLoadError = loadError => {
    const componentName = loadError.elementTagName;
    const error = loadError.error;
    console.log(componentName + ' failed to load');
    console.log(`${error.type}: ${error.message}`);
  };

  return (
    <>
      {hasLoadedSCInstance && hasConnection && (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectBalances onLoadError={onLoadError} />
        </ConnectComponentsProvider>
      )}
      {!hasLoadedSCInstance && (
        <center>
          <CircularProgress />
        </center>
      )}
    </>
  );
};

export default DashboardBalance;
