import { updateCommissionSettings, updateCompanyFeatures } from '@api/company';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
  addTapToTipTour,
  editTapToTipTour,
  fetchAgentsProfiles,
  multipleAssign
} from '../../../api/taptotip';
import { deleteTour, fetchAvailableTours } from '../../../api/tour';
import { extractStatusBarError, snackbarStatus } from '../../StatusBar';
import { setSnackbarStatus } from '../../StatusBar/actions';
import * as actions from './actions';
import * as types from './types';

function* updatePostTipFeatureWorker({ payload: { companyId, features } }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));

    const updatedCompany = yield call(updateCompanyFeatures, companyId, features);

    yield put(
      actions.updatePostTipFeatureSuccess(updatedCompany.companyDetails.features.postTipPage)
    );
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (err) {
    console.error('Error updating post tip features:', err);
  }
}

function* fetchAgentsProfilesWorker() {
  try {
    const agentsProfiles = yield call(fetchAgentsProfiles);

    yield put(actions.fetchAgentsProfilesSuccess(agentsProfiles));
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* fetchToursWorker() {
  try {
    const toursResponse = yield call(fetchAvailableTours, 'active');
    yield put(actions.fetchToursSuccess(toursResponse.data.data.tours));
  } catch (error) {
    yield put(actions.facedError(error));
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.message)));
  }
}

function* addTapToTipTourWorker({ payload }) {
  try {
    yield call(addTapToTipTour, payload);
    yield put(setSnackbarStatus(snackbarStatus.success));
    yield put(actions.fetchTours());
    yield fetchToursWorker();
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* editTapToTipTourWorker({ payload }) {
  try {
    yield call(editTapToTipTour, payload);
    yield put(setSnackbarStatus(snackbarStatus.success));
    yield put(actions.fetchTours());
    yield fetchToursWorker();
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* multipleAssignWorker({ payload: { tourIds, guideIds } }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));
    yield call(multipleAssign, tourIds, guideIds);
    yield put(setSnackbarStatus(snackbarStatus.success));
    yield put(actions.fetchAgentsProfiles());
  } catch (err) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

function* deleteTourWorker({ payload }) {
  try {
    yield call(deleteTour, payload);
    yield put(setSnackbarStatus(snackbarStatus.success));
    yield put(actions.fetchTours());
    yield fetchToursWorker();
  } catch (error) {
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(error.details)));
  }
}

function* updateCommissionSettingsWorker({ payload: { companyId, commissionSettings } }) {
  try {
    yield put(setSnackbarStatus(snackbarStatus.saving));
    const updatedCompany = yield call(updateCommissionSettings, companyId, commissionSettings);

    yield put(actions.updateCommissionSettingsSuccess(updatedCompany.paymentCommission));
    yield put(setSnackbarStatus(snackbarStatus.success));
  } catch (err) {
    console.error('Error updating post tip features:', err);
    yield put(setSnackbarStatus(snackbarStatus.error, '', extractStatusBarError(err.message)));
  }
}

export default function* postTipFeatureSaga() {
  yield takeLatest(types.UPDATE_POST_TIP_FEATURES, updatePostTipFeatureWorker);
  yield takeLatest(types.FETCH_AGENTS_PROFILES, fetchAgentsProfilesWorker);
  yield takeLatest(types.ADD_TTT_TOUR, addTapToTipTourWorker);
  yield takeLatest(types.EDIT_TTT_TOUR, editTapToTipTourWorker);
  yield takeLatest(types.FETCH_TOURS, fetchToursWorker);
  yield takeLatest(types.MULTIPLE_ASSIGN, multipleAssignWorker);
  yield takeLatest(types.DELETE_TOUR, deleteTourWorker);
  yield takeLatest(types.UPDATE_COMMISSION_SETTINGS, updateCommissionSettingsWorker);
}
