import { StyleBreakpoints } from '@constants';
import { AppBar, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  subMenu: {
    display: 'none',
    flexDirection: 'row',
    height: '89px',
    position: 'relative',
    marginTop: '-20px',
    background: 'white',
    justifyContent: 'center',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    paddingBottom: '20px',
    color: '#000',
    paddingLeft: '35px',

    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      display: 'flex',
      flexDirection: 'column',
      width: '262px',
      justifyContent: 'flex-start',
      minHeight: '100vh',
      paddingTop: '14px',
      boxShadow: 'none',
      marginLeft: '0',
      backgroundColor: '#fff',
      paddingRight: '0 !important',
      position: 'sticky',
      overflowY: 'auto'
    },

    '&::-webkit-scrollbar': {
      width: '8px',
      height: '0px',
      paddingRight: '6px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#e7e7e7',
      width: '8px',
      borderRadius: '4px'
    }
  },
  menuItem: {
    margin: '8px auto'
  },
  itemIcon: {
    width: '22px',
    minWidth: '22px'
  },
  itemText: {
    marginLeft: '35px'
  },
  betaBadge: {
    float: 'right',
    fontSize: '10px !important',
    padding: '2px 8px',
    borderRadius: '4px',
    color: '#fff',
    backgroundColor: '#4b4b4b',
    fontWeight: 600,
    position: 'absolute',
    right: '4px'
  }
}));

const SubMenu = ({ tabsInfo, exactTab }) => {
  const classes = useStyles();

  const handleMenuClick = sectionName => {
    const targetBox = document.getElementById(sectionName);

    if (targetBox) {
      targetBox.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (exactTab) {
      handleMenuClick(exactTab);
    }
  }, [exactTab]);

  if (!tabsInfo?.length) {
    return null;
  }

  return (
    <AppBar className={classes.subMenu}>
      <List component="nav">
        {tabsInfo.map((tabInfo, key) => {
          return (
            <ListItem
              button
              key={key}
              className={classes.menuItem}
              onClick={() => handleMenuClick(tabInfo.name)}>
              <ListItemIcon className={classes.itemIcon}>
                <img src={tabInfo.icon} width={22} alt={tabInfo.label} />
              </ListItemIcon>
              <ListItemText primary={tabInfo.label} className={classes.itemText} />
              {tabInfo.isBetaFeature && <div className={classes.betaBadge}>Beta</div>}
            </ListItem>
          );
        })}
      </List>
    </AppBar>
  );
};

export default SubMenu;
